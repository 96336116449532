<template>
  <div>
    <section class="section is-main-section">
      <h1 class="title">ສ້າງບິນຮັບຝາກເຄື່ອງເປັນກຸ່ມ</h1>
      <div class="columns">
        <div class="column is-4">
          <div class="panel is-primary">
            <div class="panel-heading">ຕົ້ນທາງ</div>
            <div v-if="sender" style="padding: 1em">
              <div class="columns">
                <div class="column">
                  <b-field label="ຜູ້ສົ່ງ">
                    {{ sender.tel }} - {{ sender.name }}
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div
                  class="column"
                  v-if="sender.isCod && sender.customer_account"
                >
                  <div class="columns">
                    <div class="column">
                      <b-field label="ເລກບັນຊີ COD"> </b-field>
                    </div>
                    <div class="column">
                      <ul>
                        <li
                          v-for="(acc, index) in sender.customer_account"
                          :key="index"
                        >
                          <span class="tag" :class="ccys[acc.ccy]">
                            {{ acc.account }} {{ acc.ccy }}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="columns card">
            <div class="column has-text-centered">
              <b-field label="ລວມຄ່າຝາກ LAK">
                <strong style="font-size: 1.5em">
                  {{ totalFee("LAK") | formatnumber }}
                </strong>
              </b-field>
            </div>
            <div class="column has-text-centered">
              <b-field label="ລວມຄ່າຝາກ THB">
                <strong style="font-size: 1.5em">
                  {{ totalFee("THB") | formatnumber }}
                </strong>
              </b-field>
            </div>
            <div class="column has-text-centered">
              <b-field label="ລວມ COD ກີບ">
                <strong style="font-size: 1.5em">
                  {{ totalCOD("lak") | formatnumber }}
                </strong>
              </b-field>
            </div>
            <div class="column has-text-centered">
              <b-field label="ລວມ COD ບາດ">
                <strong style="font-size: 1.5em">
                  {{ totalCOD("thb") | formatnumber }}
                </strong>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="ວີທີຊຳລະ">
                <multiselect
                  v-model="selectedPayment"
                  :options="paymentmethods"
                  track-by="id"
                  label="name"
                  placeholder="ວີທີຊຳລະ"
                  select-label=""
                  selected-label=""
                  deselect-label=""
                  @input="onPaymentMethodSelected"
                >
                </multiselect>
              </b-field>
            </div>
            <div class="column">
              <b-field label="ໝາຍເຫດ">
                <b-input
                  type="textarea"
                  v-model="remark"
                  placeholder="ໝາຍເຫດ"
                  :rows="2"
                />
              </b-field>
            </div>
            <div class="column has-text-centered">
              <button class="button is-success is-large" @click="save()">
                <b-icon icon="check" custom-size="default"></b-icon>
                <span>ບັນທຶກ</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <div class="panel is-success">
            <div class="panel-heading">
              ປາຍທາງ ແລະ ເຄື່ອງຝາກ
              <div class="buttons is-pulled-right">
                <b-button
                  class="is-pulled-right"
                  @click="addShipment()"
                  icon-left="plus"
                >
                </b-button>
              </div>
            </div>
            <div style="padding: 1em">
              <b-table
                v-if="shipments"
                :striped="true"
                :hoverable="true"
                :data="shipments"
                :pagination-rounded="true"
              >
                <b-table-column label="#" v-slot="props">
                  <p style="padding: 0.5em">{{ props.index + 1 }}</p>
                  <b-button
                    v-if="props.index > 0"
                    class="is-danger is-small"
                    @click="removeShipment(props.index)"
                    icon-left="delete"
                  >
                  </b-button>
                </b-table-column>
                <b-table-column label="ຜູ້ຮັບປາຍທາງ" v-slot="props">
                  <b-field>
                    <multiselect
                      v-model="shipments[props.index].to_branch"
                      :options="branches"
                      track-by="id"
                      :custom-label="branchOptions"
                      label="name"
                      placeholder="ສາຂາ"
                      select-label=""
                      selected-label=""
                      deselect-label=""
                    >
                    </multiselect>
                  </b-field>
                  <b-field>
                    <p class="control">
                      <b-input
                        v-model="shipments[props.index].receivername"
                        placeholder="ຊື່ຜູ້ຮັບ"
                        required
                      />
                    </p>
                    <p class="control">
                      <b-input
                        v-model="shipments[props.index].receivertel"
                        placeholder="ເບີໂທ 20xxxxxxxx"
                        required
                      />
                    </p>
                  </b-field>
                </b-table-column>
                <b-table-column label="ປະເພດເຄື່ອງ" v-slot="props">
                  <b-field>
                    <multiselect
                      v-model="shipments[props.index].parcel['parceltype']"
                      :options="parceltypes"
                      track-by="id"
                      :custom-label="parceltypeOptions"
                      label="name"
                      placeholder="ປະເພດເຄື່ອງ"
                      select-label=""
                      selected-label=""
                      deselect-label=""
                    />
                    <b-field label="ປະເພດ" label-position="on-border">
                      <b-input
                        v-if="
                          shipments[props.index].parcel['parceltype'] &&
                          shipments[props.index].parcel['parceltype']['id'] ===
                            0
                        "
                        v-model="shipments[props.index].parcel.remark"
                        placeholder="ປະເພດ"
                      />
                    </b-field>
                  </b-field>
                  <b-field>
                    <b-field label="ກວ້າງ" label-position="on-border">
                      <b-input
                        v-model="shipments[props.index].parcel.width"
                        placeholder="ກວ້າງ"
                        type="number"
                        :min="1"
                      />
                    </b-field>
                    <b-field label="ຍາວ" label-position="on-border">
                      <b-input
                        v-model="shipments[props.index].parcel.long"
                        placeholder="ຍາວ"
                        type="number"
                        :min="1"
                      />
                    </b-field>
                    <b-field label="ສູງ" label-position="on-border">
                      <b-input
                        v-model="shipments[props.index].parcel.height"
                        placeholder="ສູງ"
                        type="number"
                        :min="1"
                      />
                    </b-field>
                    <b-field label="ນ້ຳໜັກ" label-position="on-border">
                      <b-input
                        v-model="shipments[props.index].parcel.weight"
                        placeholder="ໜັກ"
                        type="number"
                        :min="1"
                      />
                    </b-field>
                  </b-field>
                </b-table-column>
                <b-table-column label="ຈຳນວນເຄື່ອງ" v-slot="props">
                  <b-field>
                    <b-input
                      v-model="shipments[props.index].parcel.qty"
                      :min="0"
                      placeholder="ຈຳນວນ"
                      type="number"
                    />
                  </b-field>
                  <b-field>
                    <b-checkbox
                      style="margin-top: 0.8em"
                      v-model="shipments[props.index].is_receiver_pay"
                      :native-value="false"
                      :type="'boolean'"
                      @input="onCheckSenderPay(props.index)"
                    >
                      ເກັບປາຍທາງ
                    </b-checkbox>
                  </b-field>
                </b-table-column>
                <b-table-column label="ຄ່າຂົນສົ່ງ (x1000)" v-slot="props">
                  <b-field>
                    <b-input
                      v-model.number="shipments[props.index].parcel.amount"
                      placeholder="LAK"
                      type="number"
                      :min="0"
                      class="has-text-right"
                    />
                  </b-field>
                  <b-field>
                    <b-input
                      v-model.number="shipments[props.index].parcel.amount_thb"
                      placeholder="THB"
                      type="number"
                      :min="0"
                      class="has-text-right"
                    />
                  </b-field>
                </b-table-column>
                <b-table-column label="COD" v-slot="props">
                  <b-field>
                    <b-input
                      v-model.number="shipments[props.index].codlak"
                      placeholder="LAK"
                      :min="0"
                      type="number"
                    />
                  </b-field>
                  <b-field>
                    <b-input
                      style="margin-top: 0.3em"
                      v-model.number="shipments[props.index].codthb"
                      placeholder="THB"
                      :min="0"
                      type="number"
                    />
                  </b-field>
                </b-table-column>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { get } from "vuex-pathify";
import Multiselect from "vue-multiselect";
export default {
  name: "BatchShipmentForm",
  components: { Multiselect },
  computed: {
    user: get("user"),
    branches: get("branches"),
    parceltypes: get("parceltypes"),
    exchangerate: get("exchangerate"),
    paymentmethods() {
      const items = this.$store.get("paymentmethods");
      return this._.filter(items, (s) => s.id !== "selftransfer");
    },
  },
  data() {
    return {
      ccys: { LAK: "is-info", THB: "is-danger", USD: "is-success" },
      sendertel: null,
      sender: null,
      remark: null,
      shipments: [],
      selectedPayment: null,
    };
  },
  mounted() {
    //this.shipments[0].parcel.parceltype = this.parceltypes[0];
    this.addShipment();
    this.selectedPayment = this.paymentmethods[0];
    this.sendertel = this.$route.params["sendertel"];
    if (this.sendertel) {
      axios
        .get(`customers/get/${this.sendertel}`)
        .then((res) => {
          console.log(res);
          if (!res.isCod) {
            this.$buefy.toast.open({
              message: "ລູກຄ້າບໍ່ໄດ້ລົງທະບຽນ COD",
              type: "is-warning",
            });
            this.$router.back();
            return;
          }
          this.sender = res;
        })
        .catch((e) => {
          console.error(e);
        });
    }
  },
  methods: {
    totalFee(ccy) {
      if (this.shipments) {
        let sum = { LAK: 0, THB: 0 };
        this.shipments.forEach((s) => {
          sum["LAK"] += 1000 * s.parcel.amount * s.parcel.qty;
          sum["THB"] += 1 * s.parcel.amount_thb * s.parcel.qty;
        });
        return sum[ccy];
      }
      return 0;
    },
    totalCOD(ccy) {
      if (this.shipments) {
        let sum = 0;
        this.shipments.forEach((s) => {
          sum += (ccy == "lak" ? 1000 : 1) * s[`cod${ccy}`];
        });
        return sum;
      }
      return 0;
    },
    addShipment() {
      this.shipments.push({
        parcel: {
          width: 1,
          long: 1,
          height: 1,
          weight: 1,
          qty: 1,
          amount: 0,
          amount_thb: 0,
          parceltype: this.parceltypes[0],
        },
        codlak: 0,
        codthb: 0,
      });
    },
    removeShipment(i) {
      if (this.shipments[i]) this.shipments.splice(i, 1);
    },
    save() {
      if (!this.selectedPayment) {
        this.$buefy.snackbar.open({
          message: `ກະລຸນາເລືອກວິທີຊໍາລະ`,
          type: "is-warning",
          queue: false,
        });
        return;
      }
      if (!this.sender) {
        this.$buefy.snackbar.open({
          message: `ບໍມີຂໍ້ມູນລູກຄ້າ`,
          type: "is-warning",
          queue: false,
        });
        return;
      }
      if (!this.shipments || this.shipments.length === 0) {
        this.$buefy.snackbar.open({
          message: `ບໍມີຂໍ້ມູນປາຍທາງ`,
          type: "is-warning",
          queue: false,
        });
        return;
      }

      let validated = true;
      for (let index = 0; index < this.shipments.length; index++) {
        const item = this.shipments[index];
        if (!item.to_branch) {
          validated = false;
          this.$buefy.snackbar.open({
            message: `${index + 1} ກະລຸນາເລືອກສາຂາປາຍທາງ`,
            type: "is-warning",
            queue: false,
          });
        }
        if (!item.receivername || item.receivername.trim() === "") {
          validated = false;
          this.$buefy.snackbar.open({
            message: `${index + 1} ກະລຸນາປ້ອນຊື່ຜູ້ຮັບປາຍທາງ`,
            type: "is-warning",
            queue: false,
          });
        }
        if (!item.receivertel || item.receivertel.trim() === "") {
          validated = false;
          this.$buefy.snackbar.open({
            message: `${index + 1} ກະລຸນາປ້ອນຊື່ຜູ້ຮັບປາຍທາງ`,
            type: "is-warning",
            queue: false,
          });
        }
        if (!item.parcel || !item.parcel["parceltype"]) {
          validated = false;
          this.$buefy.snackbar.open({
            message: `${index + 1} ກະລຸນາເລືອກປະເພດເຄື່ອງຝາກ`,
            type: "is-warning",
            queue: false,
          });
        }
        if (
          item.parcel["parceltype"] &&
          item.parcel["parceltype"].id === 0 &&
          (!item.parcel["remark"] || item.parcel["remark"].trim() === "")
        ) {
          validated = false;
          this.$buefy.snackbar.open({
            message: `${index + 1} ກະລຸນາປ້ອນຊື່ປະເພດເຄື່ອງຝາກ`,
            type: "is-warning",
            queue: false,
          });
        }
        if (
          !item.parcel.width ||
          !item.parcel.long ||
          !item.parcel.height ||
          !item.parcel.weight
        ) {
          validated = false;
          this.$buefy.snackbar.open({
            message: `${
              index + 1
            } ກະລຸນາປ້ອນຂະໜາດ ແລະ ນໍ້າໜັກເຄື່ອງຝາກ ໃຫ້ຄົບຖ້ວນ`,
            type: "is-warning",
            queue: false,
          });
        }
        if (!item.parcel.qty || item.parcel.qty <= 0) {
          validated = false;
          this.$buefy.snackbar.open({
            message: `${index + 1} ກະລຸນາປ້ອນຈຳນວນເຄື່ອງຝາກ`,
            type: "is-warning",
            queue: false,
          });
        }
        if (item.parcel.amount === null || item.parcel.amount === undefined) {
          validated = false;
          this.$buefy.snackbar.open({
            message: `${index + 1} ກະລຸນາປ້ອນຄ່າຝາກ LAK`,
            type: "is-warning",
            queue: false,
          });
        }
        if (
          item.parcel.amount_thb === null ||
          item.parcel.amount_thb === undefined
        ) {
          validated = false;
          this.$buefy.snackbar.open({
            message: `${index + 1} ກະລຸນາປ້ອນຄ່າຝາກ THB`,
            type: "is-warning",
            queue: false,
          });
        }
      }

      if (!validated) {
        return;
      }
      const reqShipments = this.shipments;
      let invalidParcel = this._.find(
        reqShipments,
        (itm) => itm.parcel.parceltype == null
      );
      if (invalidParcel != undefined) {
        this.$buefy.toast.open({
          message: "ກະລຸນາ ເລືອກ ປະເພດເຄື່ອງຝາກ",
          type: "is-danger",
        });
        return;
      }

      reqShipments.map((shipment) => {
        shipment.sendername = this.sender.name;
        shipment.sendertel = this.sender.tel;
        shipment.from_branch = this.user.branch;
        shipment.customer_id = this.sender.id;
        shipment.fee_payment_mode = this.selectedPayment.id;
        shipment.exchangerate = this.exchangerate;
        shipment.senderaccount = this.sender.account;
        shipment.remark = this.remark;
        shipment.amount = shipment.parcel.amount;
        shipment.amount_thb = shipment.parcel.amount_thb;
        return shipment;
      });
      console.log("reqShipments", reqShipments);
      axios
        .post("shipments/addbatch", reqShipments)
        .then((res) => {
          console.log(res);
          this.$router.push({ path: `/printbatch/${res[0].batchid}` });
        })
        .catch((e) => {
          this.$buefy.snackbar.open({
            message: e,
            type: "is-danger",
            queue: false,
          });
        });
    },
    onCheckSenderPay(e) {
      console.log(e);
    },
    onCheckFeeInCod(e) {
      console.log(e);
      if (this.shipment.is_fee_in_cod) {
        this.shipment.is_receiver_pay = true;
      }
    },
    onCheckCod(e) {
      console.log(e);
    },
    branchOptions({ code, name }) {
      return `${code} — ${name}`;
    },
    parceltypeOptions({ name }) {
      return `${name}`;
    },
    onPaymentMethodSelected(selectedMode) {
      this.selectedPayment = selectedMode;
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
